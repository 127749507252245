import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '@app/shared/table/paging/model/page';
import { PagedData } from '@app/shared/table/paging/model/paged-data';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DisplayBlock } from '../helpers/helper.classes';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OrgclientService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  addOrgClient(payload: any): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.post<any>(`${environment.API}organization`, payload, options);
  }

  editOrgClient(payload: any): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.put<any>(`${environment.API}organization`, payload, options);
  }

  checkDomain(payload: any): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get<any>(`${environment.API}domain/check?domain=` + payload.domainName, options);
  }

  getOrgClient(): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get<any>(`${environment.API}organization/all?skip=0&limit=100`, options);
  }

  public getResults(page: Page, displayBlock: DisplayBlock): Observable<PagedData<any>> {
    const token = localStorage.getItem('token');
    // const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };

    return this.http.get<any>(`${environment.API}${displayBlock?.getUrl}${displayBlock?.queryparam}skip=` + page.offset + `&limit=` + page.limit + `&search=` + page.search + `&role=` + page.role + `&orgId=` + page.org + `${page.orgIds ? `&orgIds=${page.orgIds}` : ''}` + `${page.leagueIds ? `&leagueIds=${page.leagueIds}` : ''}` + `${page.agegroupIds ? `&ageGroupId=${page.agegroupIds}` : ''}` + `${page.divisionId ? `&divisionId=${page.divisionId}` : ''}`, options).pipe(
      map(data => this.getPagedData(page, data)),
      // delay(1000 * Math.random())
    );

  }

  public getScoreList(page: Page, displayBlock: DisplayBlock, payload: any): Observable<PagedData<any>> {
    const token = localStorage.getItem('token');
    // const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };

    return this.http.get<any>(`${environment.API}${displayBlock?.getUrl}?orgId=${payload?.orgId}&skip=${page.offset}&limit=${page.limit}&league=${payload?.league}&division=${payload?.division}&ageGroup=${payload?.ageGroup}`, options).pipe(
      map(data => this.getPagedData(page, data)),
    );

    // return this.http.get<any>(`${environment.API}${displayBlock?.getUrl}${displayBlock?.queryparam}skip=` + page.offset + `&limit=` + page.limit + `&search=` + page.search + `&role=` + page.role + `&orgId=` + page.org +
    //   `${page.orgIds ? `&orgIds=${page.orgIds}` : ''}` + `${page.leagueIds ? `&leagueIds=${page.leagueIds}` : ''}`
    //   + `${page.agegroupIds ? `&ageGroupId=${page.agegroupIds}` : ''}`, options).pipe(
    //     map(data => this.getPagedData(page, data)),
    //     // delay(1000 * Math.random())
    //   );

  }

  private getPagedData(page: Page, data: any): PagedData<any> {
    const pagedData = new PagedData<any>();
    page.totalElements = data?.data?.totalDocs;
    page.totalPages = page.totalElements / page.size;
    page.pageNumber = data?.data?.page;
    page.limit = data?.data?.limit;
    const start = page.pageNumber * page.size;
    const end = Math.min(start + page.size, page.totalElements);

    // for (let i = 0; i < data.data.docs.length; i++) {
    //   // const jsonObj = data?.data?.docs[i];
    //   const jsonObj: any = [];
    //   jsonObj.push({
    //     id: data?.data?.docs[i]?.id,
    //     name: data?.data?.docs[i]?.name,
    //     primaryLogo: data?.data?.docs[i]?.primaryLogo ?? "assets/images/users/user-dummy-img.jpg",
    //     actions: '',
    //     treeStatus: 'disabled',
    //   })
    //   const orgClient = new OrganizationClient(jsonObj);
    //   pagedData.data.push(orgClient);
    // }
    pagedData.data.push(data?.data?.docs);
    pagedData.page = page;
    return pagedData;
  }

  getClientDetails(payload: { id: number | string }): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get<any>(`${environment.API}organization/${payload?.id}`, options);
  }
}
