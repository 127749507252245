import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showAlertMessage } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    public http: HttpClient,
  ) { }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      showAlertMessage(error.error.message ?? "Something went wrong!", "error");
    } else {
      showAlertMessage(error ?? "Something bad happened; please try again later.", "error");
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }

  createSendPushNotification(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}user-notification/send-notification`, payload).pipe(catchError(this.handleError))
  }

  getNotifications(payload: any): Observable<any> {
    return this.http.get<any>(`${environment.API}user-notification?skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`, payload).pipe(catchError(this.handleError))
  }

  readNotification(id: number): Observable<any> {
    return this.http.get<any>(`${environment.API}user-notification/read/${id}?flag=true`).pipe(catchError(this.handleError))
  }

  getNotificationSettings(id: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}notification-settings/organization/${id}`).pipe(catchError(this.handleError))
  }

  updateNotificationSettings(payload: object): Observable<any> {
    return this.http.put(`${environment.API}notification-settings`, payload).pipe(catchError(this.handleError))
  }
}
