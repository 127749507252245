import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'daysAgoPipe',
    pure: true,
})
export class DaysAgoPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const currentTime = new Date();
            const notificationTime = new Date(value);

            // Calculate the difference in milliseconds
            const diffInMilliseconds = Math.abs(currentTime.getTime() - notificationTime.getTime());

            // If the difference is less than 24 hours, display the time in "Just Now", "x hours ago", etc. format
            if (diffInMilliseconds < 86400000) {
                const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

                if (diffInSeconds < 30) {
                    return 'Just Now';
                }

                const intervals: any = {
                    hour: 3600,
                    minute: 60,
                    second: 1,
                };

                for (const i in intervals) {
                    const counter = Math.floor(diffInSeconds / intervals[i]);
                    if (counter > 0) {
                        if (counter === 1) {
                            return counter + ' ' + i + ' ago'; // singular (1 hour ago)
                        } else {
                            return counter + ' ' + i + 's ago'; // plural (2 hours ago)
                        }
                    }
                }
            } else {
                // If the difference is greater than 24 hours, return the date in the "jan,21,2024" format
                const options: any = { month: 'short', day: 'numeric', year: 'numeric' };
                return notificationTime.toLocaleDateString('en-US', options);
            }
        }
        return value;
    }
}
