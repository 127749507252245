import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
    transform(value: string, ellipsisLength: number): string {
        if (value && value.length > ellipsisLength) {
            return value.substring(0, ellipsisLength) + '...';
        } else {
            return value;
        }
    }
}
