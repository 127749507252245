import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currentTimezone',
})
export class CurrentTimezonePipe implements PipeTransform {
    transform(isoTimeString: string): string {
        if (!isoTimeString) return '';

        // Create a Date object from the ISO 8601 string (in UTC)
        const utcDate = new Date(isoTimeString);

        // Get the user's local time zone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Convert the UTC time to the user's local time zone
        const localTime = utcDate.toLocaleString('en-US', {
            timeZone: userTimeZone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return localTime;
    }
}
