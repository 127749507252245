// global-click-control.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClickControlService {
  private clickDisabled: boolean = false;

  constructor() { }

  setClickDisabled(disabled: boolean) {
    // this.clickDisabled = disabled;
    this.clickDisabled = false;
  }

  isClickDisabled(): boolean {
    return this.clickDisabled;
  }
}
