import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { WebSettingsService } from '@app/core/services/websettings.service';

@Component({
  selector: 'app-deactivate-client',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './deactivate-client.component.html',
  styleUrl: './deactivate-client.component.scss'
})
export class DeactivateClientComponent implements OnInit {

  isDeactive = true;
  isSuccess = false;
  @Input() clientID!: string;
  @Input() status!: string;

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
  }

  constructor(private webSettingsService: WebSettingsService) {
  }

  step1Validation() {
    console.log('step 1 Validation Function');
  }

  step2Validation() {
    console.log('step 2 Validation Function');
  }

  handleClick() {
    const payload = {
      "id": this.clientID,
      "isActive": false, // !this.status
    }

    this.webSettingsService.WebSettingsDeactivateClient(payload).subscribe((response: any) => {
      if (response?.status) {
        // pending to change response?.message - message to be generic from the API side;
        //this.showSaveSuccess('Details saved successfully');
        this.isSuccess = true;
        this.isDeactive = false;
      }
    })



  }

}
