import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneNumber',
    pure: true,
})
export class PhoneNumberPipe implements PipeTransform {
    transform(value: string): string {
        const digits = value?.replace(/\D/g, '');

        const formattedNumber = `(${digits?.slice(0, 3)}) ${digits?.slice(3, 6)}-${digits?.slice(6, 10)}`;

        return formattedNumber;
    }
}