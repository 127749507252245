import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private localStorageSubject = new BehaviorSubject<any>(null);

  constructor() {
    const value = localStorage.getItem('activeLink');
    if (value === 'Clinics & Showcases' || value === 'Camps' || value === 'Leagues') {
      this.localStorageSubject.next(value);
    }
  }


  signOut(): void {
    localStorage.clear();
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public saveUser(user: any): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public saveData(KEY: string, data: any): void {
    localStorage.setItem(KEY, JSON.stringify(data));
    this.localStorageSubject.next(data);
  }

  public saveSingleKey(KEY: string, data: any): void {
    localStorage.setItem(KEY, data);
    this.localStorageSubject.next(data);
  }

  public removeLocalData(KEY: string): void {
    localStorage.removeItem(KEY);
  }

  public getSaveData(KEY: string): any {
    const data = localStorage.getItem(KEY);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  getLocalStorageObservable() {
    return this.localStorageSubject.asObservable();
  }

}
