<div class="flex text-center justify-center gap-12 mt-0 w-full h-full">
  <div *ngIf="isDeactive" class="flex-col flex flex-wrap justify-center items-center p-68">
    <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
      Are you sure you would like to deactivate this client?
    </p>
    <p><span class="font-bold">Note:</span>This does not deactivate any child-organizations this client may have.</p>

    <button
      type="button"
      (click)="handleClick()"
      class="mt-10 my-50 text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20">
      Confirm Deactivation
    </button>
  </div>

  <div *ngIf="isSuccess" class="flex-col flex flex-wrap justify-center items-center p-68">
    <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
      Success! you have successfully deactivated the selected client.
    </p>
    <div class="px-4 py-3">
      <img src="/assets/images/success@2x.png" class="mb-3 h-24 w-24" />
    </div>
  </div>
</div>
