// shared.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private clickSubject: Subject<void> = new Subject<void>();
    public click$ = this.clickSubject.asObservable();

    constructor() { }

    emitClickEvent(): void {
        this.clickSubject.next();
    }
}
