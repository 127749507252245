import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LUCIDE_ICONS, LucideAngularModule, LucideIconProvider, icons } from 'lucide-angular';

@Component({
  selector: 'pagination-controls',
  standalone: true,
  templateUrl: './pagination.component.html',
  imports: [CommonModule, LucideAngularModule],
  providers: [{ provide: LUCIDE_ICONS, multi: true, useValue: new LucideIconProvider(icons) }]
})
export class NGXPagination implements OnChanges, OnInit {
  @Input() currentPage!: number;
  @Input() itemsPerPage: number = 3;
  @Input() total!: number;
  @Input() boundryLinks: boolean = false;
  @Input() darkLinks: boolean = false;
  @Input() previousLabel: string = 'false';
  @Input() nextLabel: string = 'false';
  @Output() pageChanged = new EventEmitter<number>();
  totalPages: number = 0;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['total'] || changes['itemsPerPage']) {
      this.totalPages = Math.ceil(this.total / this.itemsPerPage);
    }
  }

  ngOnInit() {
    this.totalPages = Math.ceil(this.total / this.itemsPerPage);
  }

  // get pages(): number[] {
  //   return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  // }

  // changePage(pageNumber: number): void {
  //   if (pageNumber >= 1 && pageNumber <= this.totalPages) {
  //     this.pageChanged.emit(pageNumber);
  //   }
  // }

  get pages(): (number | string)[] {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const pages: (number | string)[] = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, '...', totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }

    return pages;
  }

  changePage(page: number | string) {
    if (typeof page === 'number' && page !== this.currentPage) {
      this.pageChanged.emit(page);
    }
  }

  isPrevDisabled(): boolean {
    return this.currentPage === 1;
  }

  isNextDisabled(): boolean {
    return this.currentPage === this.totalPages;
  }
}
