<div class="flex text-center justify-center gap-12 mt-0 w-full h-full">
  <div *ngIf="isDeactive" class="flex-col flex flex-wrap justify-center items-center p-68">
    <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
      Are you sure you want to {{ status ? 'unpublish' : 'publish' }} this website?
               {{ status ? 'Doing so will remove all data from the marketing URL of this client.' : '' }}
    </p>
    <!-- <p><span class="font-bold">Note:</span>This does not deactivate any child-organizations this client may have.</p> -->

    <button *ngIf="status"
      type="button"
      (click)="handleClick()"
      class="mt-10 my-50 text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20">
      Confirm {{ status ? 'Unpublish' : 'Publish' }}
    </button>

    <button *ngIf="!status"
      type="button"
      (click)="handleClick()"
      class="mt-10 my-50 text-white bg-green-500  border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-custom-400/20">
      Confirm {{ status ? 'Unpublish' : 'Publish' }}
    </button>
  </div>

  <div *ngIf="isSuccess" class="flex-col flex flex-wrap justify-center items-center p-68">
    <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
      Success! you have successfully {{ status ? 'Unpublish' : 'Publish' }} the selected client.
    </p>
    <div class="px-4 py-3">
      <img src="/assets/images/success@2x.png" class="mb-3 h-24 w-24" />
    </div>
  </div>
</div>



<!-- <div class="">
  <ng-stepper
    class="grid grid-cols-1 gap-3 lg:grid-cols-4 nav-tabs form-wizard">

    <cdk-step [optional]="false" class="group/item active">
      <ng-template
        cdkStepLabel>
      <div class="tab-pane text-center" data-tab-id="1" id="personalInfo">
        <button
          cdkStepperNext
          data-action="next"
          #childNextButton></button>
          <div class="flex-col flex flex-wrap justify-center items-center">
            <p class="w-full mb-6 text-lg text-slate-500 dark:text-zink-200">
              Are you sure you want to {{ status ? 'unpublish' : 'publish' }} this website?
               {{ status ? 'Doing so will remove all data from the marketing URL of this client.' : '' }}?
            </p>


          </div>
      </div>
      </ng-template>
    </cdk-step>

    <cdk-step [optional]="true" class="group/item">
      <ng-template cdkStepLabel> </ng-template>
      <div class="tab-pane text-center" data-tab-id="2" id="personalInfo2">
        <div *ngIf="isLoading" class="">
          <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
            Hold tight, we're building the website.
          </p>
          <div
            (click)="handleClick()"
            class="flex flex-wrap justify-center items-center gap-5">
            <div
              class="inline-block size-8 border-2 border-green-500 rounded-full animate-spin border-l-transparent"></div>
          </div>
        </div>
        <div
          *ngIf="isSuccess"
          class="flex-col flex flex-wrap justify-center items-center">
          <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
            Success! You have successfully generated this client's marketing website.
          </p>
          <div class="px-4 py-3">
            <img src="/assets/images/success@2x.png" class="mb-3 h-24 w-24" />
          </div>
          <div class="px-4 py-3">
            <button
              type="button"
              (click)="openExternalLink()"
              class="text-white btn bg-slate-500 border-slate-500 hover:text-white hover:bg-slate-600 hover:border-slate-600 focus:text-white focus:bg-slate-600 focus:border-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:border-slate-600 active:ring active:ring-slate-100 dark:ring-slate-400/10">
              visit website
            </button>
          </div>
        </div>
      </div>
    </cdk-step>

  </ng-stepper>
</div> -->
