import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showAlertMessage } from '../helpers/utils';

interface IListPayload { orgId: number; skip: number; limit: number; isActive: boolean }

@Injectable({
  providedIn: 'root'
})
export class LeagueService {

  constructor(
    public http: HttpClient,
  ) { }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      showAlertMessage(error.error.message ?? "Something went wrong!", "error");
    } else {
      showAlertMessage(error ?? "Something bad happened; please try again later.", "error");
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }

  getLeagueList(payload: any) {
    return this.http.get<any>(`${environment.API}league?orgIds=${payload?.orgIds}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(this.handleError));
  }

  getLeagueLocations(payload: IListPayload): Observable<any> {
    return this.http.get<any>(`${environment.API}league/location?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(this.handleError))
  }

  createLeagueLocation(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}league/location`, payload).pipe(catchError(this.handleError))
  }

  getLeagueDivison(payload: IListPayload): Observable<any> {
    return this.http.get<any>(`${environment.API}league/division?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(this.handleError))
  }

  createLeagueDivision(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}league/division`, payload).pipe(catchError(this.handleError))
  }

  // getLeagueDocument(payload: IListPayload): Observable<any> {
  //   return this.http.get<any>(`${environment.API}league/document?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(this.handleError))
  // }

  getLeagueDocument(): Observable<any> {
    return this.http.get<any>(`${environment.API}league/documents`).pipe(catchError(this.handleError))
  }

  // createLeagueDocument(payload: any): Observable<any> {
  //   return this.http.post<any>(`${environment.API}league/document`, payload).pipe(catchError(this.handleError))
  // }

  createLeagueDocument(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}league/common/document`, payload).pipe(catchError(this.handleError))
  }

  createLeague(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}league`, payload).pipe(catchError(this.handleError))
  }

  updateLeague(payload: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}league/${payload?.id}`, payload).pipe(catchError(this.handleError))
  }

  getLeagueById(id: number) {
    return this.http.get<any>(`${environment.API}league/${id}`).pipe(catchError(this.handleError));
  }

  getLeagueAgeGroup(payload: IListPayload): Observable<any> {
    return this.http.get<any>(`${environment.API}league/age-group?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(this.handleError))
  }

  createLeagueAgeGroup(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}league/age-group`, payload).pipe(catchError(this.handleError))
  }

  getTeamNotRegisterInLeague(leagueId: number) {
    return this.http.get<any>(`${environment.API}team/not/register/${leagueId}`).pipe(catchError(this.handleError));
  }

  teamApprovalRequest(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}team/register/request`, payload).pipe(catchError(this.handleError))
  }

  getTeamDetail(id: number) {
    return this.http.get<any>(`${environment.API}team/deatil/with/price/${id}`).pipe(catchError(this.handleError));
  }

  getLeaguePaymentData(payload: any) {
    return this.http.post<any>(`${environment.API}league/league-hash-noauth`, payload);
  }

  leaguePaymentIntent(payload: any) {
    return this.http.post<any>(`${environment.API}league/league-intent-noauth`, payload).pipe(catchError(this.handleError))
  }

  leaguePayment(payload: any) {
    return this.http.post<any>(`${environment.API}league/league-payment-noauth`, payload).pipe(catchError(this.handleError))
  }
  sendInvoice(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}league/send-invoice`, payload).pipe(catchError(this.handleError))
  }

  getLeagueDocumentsById(leagueId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}league/required/documents/${leagueId}`).pipe(catchError(this.handleError))
  }

  getLeagueAgeGroupByLeagueId(leagueId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}league/age/groups/${leagueId}`).pipe(catchError(this.handleError))
  }

  createLeagueTeamDocument(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}league/team-document`, payload).pipe(catchError(this.handleError))
  }

  getLeagueTeamDocument(payload: IListPayload): Observable<any> {
    return this.http.get<any>(`${environment.API}league/team-document?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}`).pipe(catchError(this.handleError))
  }

  updateTeamStatus(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}team/register/request/status`, payload).pipe(catchError(this.handleError))
  }

  getExportedData(payload: any): Observable<any> {
    return this.http.get<any>(`${environment.API}team/roster/documents/${payload?.teamId}`).pipe(catchError(this.handleError))
  }

  getLeagueDivisionByLeagueId(leagueId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}league/division/${leagueId}`).pipe(catchError(this.handleError))
  }

  getDivisionFilterData(payload: any): Observable<any> {
    return this.http.get<any>(`${environment.API}team/league/${payload?.leagueId}?divisionId=${payload?.divisionId}`).pipe(catchError(this.handleError))
  }
}
