import { createAction, createReducer, on, props } from '@ngrx/store';

// Define the initial state
export interface CommunityState {
    name: string;
    accesscode: string;
    parentOrgId: string;
    primaryLogo: string;
    showChildEvents: boolean;
}

export const initialCommunityState: CommunityState = {
    name: '',
    accesscode: '',
    parentOrgId: '',
    primaryLogo: '',
    showChildEvents: false,
};

// Define actions
export const setCommunityPayload = createAction(
    '[Community] Set Payload',
    props<{ payload: CommunityState }>()
);

export const resetCommunityState = createAction('[Community] Reset State');


// Create the reducer
const _communityReducer = createReducer(
    initialCommunityState,
    on(setCommunityPayload, (state, { payload }) => ({ ...state, ...payload })),
    on(resetCommunityState, () => initialCommunityState) // Reset to initial state
);

export function communityReducer(state: any, action: any) {
    return _communityReducer(state, action);
}