import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ordinalDate',
    pure: true,
})
export class OrdinalDatePipe implements PipeTransform {
    transform(value: Date | string | null): string {
        if (!value) return '';
        const date = new Date(value);

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });

        return `${month} ${this.getOrdinalSuffix(day)}`;
    }

    getOrdinalSuffix(day: number): string {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const remainder = day % 100;
        return day + (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]);
    }
}
