import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserdataService {
    private userDataSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUserDetails')!));
    userData$ = this.userDataSubject.asObservable();

    constructor() { }

    setUserData(userData: any): void {
        this.userDataSubject.next(userData);
    }

    public clearCurrentUserDetail(): void {
        this.userDataSubject.next(null);
        localStorage.removeItem('currentUserDetails');
    }
}