import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showAlertMessage } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    public http: HttpClient,
  ) { }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      showAlertMessage(error.error.message ?? "Something went wrong!", "error");
    } else {
      showAlertMessage(error ?? "Something bad happened; please try again later.", "error");
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }

  getTeamsList(payload: { orgId: number; skip: number; limit: number; isActive: boolean }): Observable<any> {
    return this.http.get<any>(`${environment.API}team?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`).pipe(catchError(this.handleError))
  }

  getAllSportPositions(orgId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}position?orgId=${orgId}`).pipe(catchError(this.handleError));
  }

  createTeam(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}team`, payload).pipe(catchError(this.handleError))
  }

  updateTeam(payload: any, id: number): Observable<any> {
    return this.http.put<any>(`${environment.API}team/${id}`, payload).pipe(catchError(this.handleError))
  }

  createRoster(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}team/roster`, payload).pipe(catchError(this.handleError))
  }

  updateRoster(payload: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}team/roster`, payload).pipe(catchError(this.handleError))
  }

  deleteRoster(parentRosterId: number | string, teamId: number | string): Observable<any> {
    return this.http.delete<any>(`${environment.API}team/remove/roster/${parentRosterId}/${teamId}`).pipe(catchError(this.handleError))
  }

  getTeamDetails(id: number | string, leagueId?: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}team/${id}?leagueId=${leagueId ? leagueId : ''}`).pipe(catchError(this.handleError))
  }

  getRosterDocuments(rosterId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}team/roster/document/list/${rosterId}`).pipe(catchError(this.handleError));
  }

  deleteRosterDocument(rosterId: number | string, docId: number | string): Observable<any> {
    return this.http.delete<any>(`${environment.API}/team/remove/roster/document/${rosterId}/${docId}`).pipe(catchError(this.handleError));
  }

  addRosterDocument(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}team/roster/add/document`, payload).pipe(catchError(this.handleError))
  }

  deleteTeam(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.API}team/${id}`).pipe(catchError(this.handleError))
  }

  deleteCoach(teamId: number | string, coachId: number | string): Observable<any> {
    return this.http.delete<any>(`${environment.API}team/remove/coach/${teamId}/${coachId}`).pipe(catchError(this.handleError));
  }

  deleteManager(teamId: number | string, managerId: number | string): Observable<any> {
    return this.http.delete<any>(`${environment.API}team/remove/manager/${teamId}/${managerId}`).pipe(catchError(this.handleError));
  }

  getAllUserGrades(payload: { skip: number; limit: number; }): Observable<any> {
    return this.http.get<any>(`${environment.API}grade?skip=${payload?.skip}&limit=${payload?.limit}`).pipe(catchError(this.handleError))
  }

}
