import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 0,
    label: 'Sketchplay',
    icon: 'monitor-dot',
    link: '/a',
  },
  {
    id: 1,
    label: 'Grid iron',
    icon: 'user',
    link: '/a',
  },
  {
    id: 2,
    label: 'balgium',
    icon: "https://storage.googleapis.com/sketchplay-be1a2.appspot.com/Sk/Stage//Profile/Thumbnail/avatar1712124192720.png?GoogleAccessId=firebase-adminsdk-3pymr%40sketchplay-be1a2.iam.gserviceaccount.com&Expires=1893456000&Signature=Z0sim%2BnLvhg7MJhqKnRiiU2SSC2%2BmyjmLpNa9FjbBQFNob%2FZF5Ekp10yO7WdtpdCGwMJazyPSR4e%2BafZnkWKTYiugWb0tvu0OzmdcKzG6UlBYIrrHUe8vVzSezxQqXMqGm59bKdavSlHxh3Nfa4xMk9EYEN0vICN7sxwb9S2mbJp%2F%2BxWhBTFGH9PoAIIch6yuNmbDgjhi8EszjMPIDsjLZjyZG0TkZWflg8zfAreqSHwUXIvYuhnqIqeekC7xJbteeDAiim%2FIpi703cfhyYzPe9tgvnONq5idj%2Ft%2BLJCCAVFY63qxG0x5KXtpOkqUEeoE8kqXE25BHoTVMNB0rni8w%3D%3D",
    link: '/clients',
  },
  {
    id: 3,
    label: 'red iron basketball',
    icon: "https://storage.googleapis.com/sketchplay-be1a2.appspot.com/Sk/Stage//Profile/Thumbnail/avatar1712124192720.png?GoogleAccessId=firebase-adminsdk-3pymr%40sketchplay-be1a2.iam.gserviceaccount.com&Expires=1893456000&Signature=Z0sim%2BnLvhg7MJhqKnRiiU2SSC2%2BmyjmLpNa9FjbBQFNob%2FZF5Ekp10yO7WdtpdCGwMJazyPSR4e%2BafZnkWKTYiugWb0tvu0OzmdcKzG6UlBYIrrHUe8vVzSezxQqXMqGm59bKdavSlHxh3Nfa4xMk9EYEN0vICN7sxwb9S2mbJp%2F%2BxWhBTFGH9PoAIIch6yuNmbDgjhi8EszjMPIDsjLZjyZG0TkZWflg8zfAreqSHwUXIvYuhnqIqeekC7xJbteeDAiim%2FIpi703cfhyYzPe9tgvnONq5idj%2Ft%2BLJCCAVFY63qxG0x5KXtpOkqUEeoE8kqXE25BHoTVMNB0rni8w%3D%3D",
    link: '/c',
  },
  {
    id: 4,
    label: 'balgium',
    icon: "https://storage.googleapis.com/sketchplay-be1a2.appspot.com/Sk/Stage//Profile/Thumbnail/avatar1712124192720.png?GoogleAccessId=firebase-adminsdk-3pymr%40sketchplay-be1a2.iam.gserviceaccount.com&Expires=1893456000&Signature=Z0sim%2BnLvhg7MJhqKnRiiU2SSC2%2BmyjmLpNa9FjbBQFNob%2FZF5Ekp10yO7WdtpdCGwMJazyPSR4e%2BafZnkWKTYiugWb0tvu0OzmdcKzG6UlBYIrrHUe8vVzSezxQqXMqGm59bKdavSlHxh3Nfa4xMk9EYEN0vICN7sxwb9S2mbJp%2F%2BxWhBTFGH9PoAIIch6yuNmbDgjhi8EszjMPIDsjLZjyZG0TkZWflg8zfAreqSHwUXIvYuhnqIqeekC7xJbteeDAiim%2FIpi703cfhyYzPe9tgvnONq5idj%2Ft%2BLJCCAVFY63qxG0x5KXtpOkqUEeoE8kqXE25BHoTVMNB0rni8w%3D%3D",
    link: '/clients',
  },
  {
    id: 5,
    label: 'red iron basketball',
    icon: "https://storage.googleapis.com/sketchplay-be1a2.appspot.com/Sk/Stage//Profile/Thumbnail/avatar1712124192720.png?GoogleAccessId=firebase-adminsdk-3pymr%40sketchplay-be1a2.iam.gserviceaccount.com&Expires=1893456000&Signature=Z0sim%2BnLvhg7MJhqKnRiiU2SSC2%2BmyjmLpNa9FjbBQFNob%2FZF5Ekp10yO7WdtpdCGwMJazyPSR4e%2BafZnkWKTYiugWb0tvu0OzmdcKzG6UlBYIrrHUe8vVzSezxQqXMqGm59bKdavSlHxh3Nfa4xMk9EYEN0vICN7sxwb9S2mbJp%2F%2BxWhBTFGH9PoAIIch6yuNmbDgjhi8EszjMPIDsjLZjyZG0TkZWflg8zfAreqSHwUXIvYuhnqIqeekC7xJbteeDAiim%2FIpi703cfhyYzPe9tgvnONq5idj%2Ft%2BLJCCAVFY63qxG0x5KXtpOkqUEeoE8kqXE25BHoTVMNB0rni8w%3D%3D",
    link: '/c',
  },

];
