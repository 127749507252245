import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AdminCenterService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
      Swal.fire({
        title: 'Error',
        text: error.error.message,
        icon: 'warning',
        iconColor: 'red',
        showConfirmButton: false,
        animation: false,
        buttonsStyling: false,
        showCloseButton: true
      });
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      Swal.fire({
        title: 'Error',
        text: error ?? 'Something bad happened; please try again later.',
        icon: 'warning',
        iconColor: 'red',
        showConfirmButton: false,
        animation: false,
        buttonsStyling: false,
        showCloseButton: true
      });
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => 'Something bad happened; please try again later.');
  }



  // get user list
  getAllUserList(payload: any): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    const params = new HttpParams()
      .set('isActive', payload.isActive)
      .set('orgId', payload.orgId)
      .set('startDate', payload.startDate)
      .set('search', payload.search)
      .set('role', payload.role)
      .set('limit', 50)
      .set('skip', 0)

    const options = { headers: headers, params: params };

    return this.http.get(environment.API + 'user-list', options);
  }

  getAllRolesForUserListPage(): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + '/all-roles', options);
  }

  getOnlyUserRoles(): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + '/roles', options);
  }

  addEventSprint(payload: object): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.post(environment.API + 'event-sprint', payload, { headers });
  }

  updateEventSprint(payload: object): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.put(environment.API + 'event-sprint', payload, { headers });
  }

  deleteEventSprint(id: number | string): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.delete(environment.API + 'event-sprint/' + id, { headers });
  }

  deleteDiscount(id: number | string): Observable<any> {
    return this.http.delete(environment.API + 'discount/' + id);
  }

  addEventDiscount(payload: object): Observable<any> {
    return this.http.post(environment.API + 'discount', payload);
  }
  activeDeactiveDiscountCode(scoreId: any, payload: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}discount/${scoreId}`, payload);
  }
}
