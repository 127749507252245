import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthMainAuthenticationService } from './auth-main.service';
import { UserdataService } from './userdata.service';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  userCredential: any;
  constructor(private authMainAuthenticationService: AuthMainAuthenticationService, private userdataService: UserdataService, private router: Router) { }

  signOut(): void {
    this.userCredential = localStorage.getItem('userCredentials')
    localStorage.clear();
    localStorage.setItem('userCredentials', this.userCredential)
    this.authMainAuthenticationService.clearCurrentUser()
    this.userdataService.clearCurrentUserDetail()
    localStorage.removeItem('token');
    this.router.navigate(['/account-login']);
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public saveUser(user: any): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }
}
