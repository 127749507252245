import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Observable, from } from 'rxjs';
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../../store/Authentication/auth.models';


@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user!: User;

    constructor(private http: HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }


    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {

        return from(getFirebaseBackend().loginUser(email, password).then(user => {
            return user;
        })
        );
    }
    userLogin(res: any): Observable<any> {
        return this.http.post(environment.API + 'web-login', res);
    }
    forgotPassword(res: any): Observable<any> {
        return this.http.post(environment.API + 'forgot-password', res);
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */

    registerData(res: any): Observable<any> {
        return this.http.post(environment.API + 'sign-up', res);
    }
    enterOtp(res: any): Observable<any> {
        return this.http.post(environment.API + 'verify-account', res);
    }
    checkEmail(res: any): Observable<any> {
        return this.http.post(environment.API + 'check-email-phone', res);
    }
    register(email: any, username: any, password: any) {
        return from(getFirebaseBackend().registerUser(email, username, password).then((response: any) => {
            const user = response;
            return user;
        }));
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }

    resendOtp(res: any): Observable<any> {
        return this.http.post(environment.API + 'resend-otp', res);
    }

    changePassword(payload: object): Observable<any> {
        let token = localStorage.getItem('token')
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`
        });
        return this.http.post(environment.API + 'change-password', payload, { headers });
    }


    userMe(): Observable<any> {
        // let token = localStorage.getItem('token')
        // const headers = new HttpHeaders({
        //     'Authorization': `Bearer ${token}`
        // });
        // const options = { headers: headers };
        return this.http.get(environment.API + 'me');
    }
}


