import { Directive, HostListener, Input, Renderer2 } from '@angular/core';
import { ClickControlService } from '@app/layouts/sidebar/clickControlService';
import { SwalService } from '@app/layouts/sidebar/swalService';
import { DrawerService } from './drawer.service';

@Directive({
  selector: '[DrawerTrigger]',
})
export class DrawerTriggerDirective {
  @Input('DrawerTrigger') drawerId!: string;

  constructor(private renderer: Renderer2,
    private drawerService: DrawerService,
    private globalClickControlService: ClickControlService,
    private swalService: SwalService,
  ) { }

  @HostListener('click')
  async onClick() {
    const isClickDisabled = this.globalClickControlService.isClickDisabled();
    // Check if click is disabled or show confirmation dialog
    if (!isClickDisabled || (isClickDisabled && await this.showConfirmationDialog())) {
      this.drawerService.open(this.drawerId);
      document.body.classList.add('overflow-hidden')
      this.appendDivToBody();
    } else {
      // Perform common actions
    }
  }

  async showConfirmationDialog(): Promise<boolean> {
    return new Promise((resolve) => {
      this.swalService.conformationPopup('You will lose any progress by navigating away from this page.', 'error', () => {
        resolve(true);
      }, () => {
        resolve(false);
      }, '', 'Ok, leave anyway', 'Cancel');
    });
  }

  private appendDivToBody() {
    const div = this.renderer.createElement('div');
    this.renderer.addClass(div, 'fixed');
    this.renderer.addClass(div, 'inset-0');
    // this.renderer.addClass(div, 'bg-slate-900/40');
    // this.renderer.addClass(div, 'dark:bg-zink-800/70');
    this.renderer.addClass(div, 'z-[1049]');
    this.renderer.addClass(div, 'drawer-backdrop');
    this.renderer.appendChild(document.body, div);

    // Optionally, you can add a click event listener to handle removal
    this.renderer.listen(div, 'click', () => {
      this.removeDivFromBody(div);
    });
  }

  private removeDivFromBody(div: any) {
    this.drawerService.close(this.drawerId);
    document.body.classList.remove('overflow-hidden')
    this.renderer.removeChild(document.body, div);
  }
}
