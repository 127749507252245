import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable, catchError, throwError } from "rxjs";
import { showAlertMessage } from "../helpers/utils";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
  providedIn: 'root',
})
export class SidebarService {

  private menuItemsSubject = new BehaviorSubject<any[]>([]);
  menuItems$ = this.menuItemsSubject.asObservable();

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      showAlertMessage(error.error.message ?? "Something went wrong!", "error");
    } else {
      showAlertMessage(error ?? "Something went wrong!", "error");
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }

  featureList(orgId: any): Observable<any> {
    return this.http.get(environment.API + 'features/list?orgId=' + orgId);
  }

  communityList(skip: any, take: any, search: any): Observable<any> {
    const token = localStorage.getItem('token')
    // const myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + 'organization/public-organization?skip=' + 0 + '&limit=' + 100 + '&search=' + (search ? search : ''), options);
  }

  checkAccessCode(accessCode: string | number): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + 'organization/with/access-code/' + accessCode, options);
  }

  getAllRoles(): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + 'roles', options);
  }

  getAllRolesWithAdmin(): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + 'all-roles', options);
  }

  getAllGrades(): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + 'grade', options);
  }

  joinPrivateOrganization(res: any): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.post<any>(`${environment.API}organization-joining-request`, res, options)
  }

  viewMoreOrganizationOnTop(res: any): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.patch<any>(`${environment.API}update/user/time/${res?.id}`, '').pipe(catchError(this.handleError));
    // return this.http.patch<any>(`${environment.API}update/user/time/`${id}, options)
  }

  getAllStates(): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + 'country/states', options);
  }

  getAllCities(stateCode: string): Observable<any> {
    const token = localStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get(environment.API + `country/cities/${stateCode}`, options);
  }

  addChildCommunity(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}organization/sub-organization`, payload).pipe(catchError(this.handleError));
  }

  updateMenuItems(menuItems: any[]) {
    this.menuItemsSubject.next(menuItems);
  }

}
