import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'formatCurrency',
  pure: true,
})
export class FormatCurrencyPipe implements PipeTransform {
  transform(amount: number = 0, currency?: string): string {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "USD",
    });
    return formatter.format(amount);
  }
}
