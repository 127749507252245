<ul class="flex flex-wrap items-center gap-2 shrink-0">
  @if(boundryLinks){
  <li>
      <a href="javascript:void(0)" class="inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto"
      (click)="changePage(currentPage - 1)"
      [style.pointer-events]="isPrevDisabled() ? 'none' : 'auto'"
      [class.disabled]="isPrevDisabled()">
          <lucide-angular name="chevrons-left" [class]="'size-4 rtl-rotate-180'"></lucide-angular>
      </a>
  </li>
  }
  <li>
      <a href="javascript:void(0)" class="inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto"
      (click)="changePage(currentPage - 1)"
      [style.pointer-events]="isPrevDisabled() ? 'none' : 'auto'"
      [class.disabled]="isPrevDisabled()">
          <lucide-angular name="chevron-left" [class]="'size-4 rtl-rotate-180'"> </lucide-angular> {{previousLabel}}
      </a>
  </li>

  @if(darkLinks){
  <li *ngFor="let page of pages">
      <a href="javascript:void(0)"
      class="inline-flex items-center justify-center bg-white dark:bg-zink-700 size-8 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-white dark:[&.active]:text-white [&.active]:bg-custom-500 dark:[&.active]:bg-custom-500 [&.active]:border-custom-500 dark:[&.active]:border-custom-500 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto"
      (click)="changePage(page)" [class.active]="currentPage === page"
      *ngIf="page !== '...'; else ellipsis">
          {{ page }}
      </a>
      <ng-template #ellipsis>
          <span class="inline-flex items-center justify-center h-8 px-3 text-slate-500 dark:text-zink-200">...</span>
        </ng-template>
  </li>}
  @else{
  <li *ngFor="let page of pages">
      <a href="javascript:void(0)"
       class="inline-flex items-center justify-center bg-white dark:bg-zink-700 size-8 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto"
       (click)="changePage(page)" [class.active]="currentPage === page"
       *ngIf="page !== '...'; else ellipsis">
          {{ page }}
      </a>
      <ng-template #ellipsis>
          <span class="inline-flex items-center justify-center h-8 px-3 text-slate-500 dark:text-zink-200">...</span>
      </ng-template>
  </li>
  }
  <li>
      <a href="javascript:void(0)" class="inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto"
      (click)="changePage(currentPage + 1)"
      [style.pointer-events]="isNextDisabled() ? 'none' : 'auto'"
      [class.disabled]="isNextDisabled()">
          {{nextLabel}}<lucide-angular name="chevron-right" [class]="'size-4 rtl-rotate-180'"></lucide-angular>
      </a>
  </li>


  @if(boundryLinks){
  <li>
      <a href="javascript:void(0)" class="inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto"
      (click)="changePage(currentPage + 1)"
      [style.pointer-events]="isNextDisabled() ? 'none' : 'auto'"
      [class.disabled]="isNextDisabled()">
          <lucide-angular name="chevrons-right" [class]="'size-4 rtl-rotate-180'"></lucide-angular>
      </a>
  </li>}
</ul>
